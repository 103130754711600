import { defineComponent, toRefs, reactive, computed, watch, defineAsyncComponent } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import API from "@/api/instruction/index";
import { useMessage } from '@/hooks/web/useMessage';
import SetBother from '@/components/Instruction/SetBother.vue';
import dayjs from "dayjs";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    Eldialog,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    SetBother
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    nodeData: {
      type: Object,
      default: null
    },
    objectIds: {
      type: Object,
      default: null
    }
  },
  emits: ['update:visible'],
  setup(props, {
    emit
  }) {
    const {
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      callType: 2,
      sendTime: null,
      isSend: 0,
      time: '',
      paramInterval: '30',
      messageType: '',
      transDataType: '',
      modeType: 3,
      setBotherRef: null,
      type: 1,
      displayType: 0,
      title: '',
      content: '',
      img: '',
      holdIds: [],
      objectIds: [],
      classIds: [],
      schoolIds: []
    });
    // 客户代码
    const customCode = computed(() => state.userInfo.customCode);
    const startTime = computed(() => {
      const HH = dayjs().format('HH');
      const mm = dayjs().format('mm');
      return !isTheDay.value ? '00:00' : `${HH}:${Number(mm) % 5 !== 0 ? Number(mm) + (5 - Number(mm) % 5) : mm}`;
    });
    // 判断当前选择时间是否为当天
    const isTheDay = computed(() => {
      return dayjs().format('YYYY-MM-DD') === refData.sendTime;
    });
    watch(() => props.visible, val => {
      val && (refData.type = 26, refData.title = '', refData.content = '', refData.displayType = 0, refData.modeType = 3, refData.isSend = 0, refData.paramInterval = '30', refData.sendTime = null, refData.img = '', refData.classIds = [], refData.schoolIds = []);
    });
    // 预计下发时间限制
    const disabledDate = time => {
      return time.getTime() + 24 * 60 * 60 * 1000 < Date.now();
    };
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    //确定按钮
    const handleConfirm = async () => {
      // if(refData.type === 3){
      //   refData.setBotherRef[props.nodeData.type == 'school'?'schoolId':'classId'] = props.nodeData.id;
      //   refData.setBotherRef.isMassMailing = true;
      //   refData.setBotherRef.sendMsg();
      //   // emit('update:visible', false);
      //   return false;
      // }else if(refData.type === 5){
      //   const params = {
      //     callType:refData.callType,
      //     schoolIds:props.nodeData.type === 'school'?[props.nodeData.id]:[],
      //     classIds:props.nodeData.type !== 'school'?[props.nodeData.id]:[],
      //   }
      //   const { code,msg } = await httpApi.publishWhitePhone(params);
      //   message[code == 0?'success':'warning'](code == 0?'下发成功':msg);
      // }else{
      try {
        const {
          objectIds,
          holdIds,
          classIds,
          schoolIds,
          ...params
        } = refData;
        delete params.setBotherRef;
        if (![3].includes(refData.type) && refData.isSend === -2) {
          if (!refData.sendTime) throw {
            msg: '请填写预约日期'
          };
          if (!refData.time) throw {
            msg: '请填写预约时间'
          };
        }
        if (!params.content && refData.type !== 4) throw {
          msg: '消息内容不能为空'
        };
        if (props.nodeData == null && props.objectIds == null) {
          throw {
            msg: '发送的设备或者单位不能为空'
          };
        }
        if (props.nodeData != null) {
          params.holdIds = [props.nodeData.id];
        }
        if (props.objectIds != null) {
          params.objectIds = props.objectIds;
        }
        params.sendTime = refData.isSend === -2 ? `${refData.sendTime} ${refData.time}` : '';
        let param = {
          cmdtype: 26,
          cmddesc: refData.content,
          sendresult: refData.isSend,
          sendtime: refData.sendTime,
          cmdcontent: JSON.stringify({
            param_type: refData.messageType,
            param_content: refData.content
          })
        };
        if (refData.type == 14) {
          param = {
            cmdtype: 14,
            cmddesc: refData.content,
            sendresult: refData.isSend,
            sendtime: refData.sendTime,
            cmdcontent: JSON.stringify({
              param_type: refData.transDataType,
              param_content: refData.content
            })
          };
        }
        if (refData.type == 3) {
          param = {
            cmdtype: 3,
            cmddesc: refData.paramInterval.toString(),
            sendresult: refData.isSend,
            sendtime: refData.sendTime,
            cmdcontent: JSON.stringify({
              param_timeinterval: refData.paramInterval
            })
          };
        }
        if (props.objectIds != null) {
          param.holdIds = null;
          param.objectIds = props.objectIds;
        } else {
          param.holdIds = [props.nodeData.id];
          param.objectIds = null;
        }
        param.sendtime = refData.isSend === -2 ? `${refData.sendTime} ${refData.time}:01` : '';
        const url = '';
        const {
          code,
          msg
        } = await API.publishMsg(param);
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '下发成功' : msg);
        // code == 0 && emit('update:visible', false);
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
      // }
    };
    return {
      ...toRefs(refData),
      show,
      handleConfirm,
      disabledDate,
      startTime,
      isTheDay,
      customCode
    };
  }
});